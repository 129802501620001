@import "../variables";
@import "../helpers/mixins";

.tw_farm {
  .section {
    background: $primary2;
    @include dark {
      background: $neutrals0;
    }
  }

  .section2 {
    background: $neutrals8;
    @include dark {
      background: #0f0f0f;
    }
  }

  .section3 {
    background: $neutrals8;
    @include dark {
      background: #0f0f0f;
    }

    @include m {
      background: $primary2;
      @include dark {
        background: $neutrals0;
      }
    }
  }

  &__hero {
    display: grid;
    justify-content: center;

    &_title {
      max-width: 1078px;
      @include outfit;
      font-size: 60px;
      font-weight: 600;
      line-height: 72px;
      text-align: center;

      @include t {
        font-size: 30px;
        line-height: 43px;
      }
    }

    &_text {
      display: none;
      @include m {
        margin-top: 20px;
        display: block;
        color: $neutrals4;
        @include poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 14.4px;
        text-align: center;
      }
    }

    &__img {
      margin-top: 30px;
      width: 100%;
      height: 100%;
    }
  }

  &__JoinSteps {
    display: grid;
    justify-content: center;

    &_title {
      max-width: 731px;
      @include poppins;
      font-size: 40px;
      font-weight: 500;
      line-height: 60px;
      text-align: center;
      margin-bottom: 70px;

      @include t {
        margin-bottom: 30px;
        font-size: 26px;
        line-height: 48px;
        letter-spacing: -0.01em;
        max-width: 450px;
      }
    }

    &_sections {
      display: flex;
      gap: 20px;

      @include t {
        display: grid;
        justify-content: center;
      }
    }

    &_flex {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    &_section {
      max-width: 370px;
      min-height: 271px;
      display: grid;
      padding: 26px;
      gap: 26px;
      border-radius: 4px;
      border: 1px;
      border: 1px solid $neutrals6;
      backdrop-filter: blur(10px);
      transition: all 0.2s ease;

      &:hover {
        flex-shrink: 0;
        // border-left: 1px solid !important;
        border-image-source: linear-gradient(180deg, #8e793e 0%, rgba(177, 181, 195, 0.4) 100%) !important;
        border-image-slice: 1;
      }

      @include dark {
        border: 1px solid $navy1;

        &:hover {
          flex-shrink: 0;
          // border-left: 1px solid !important;
          border-image-source: linear-gradient(180deg, #8e793e 0%, rgba(177, 181, 195, 0.4) 100%) !important;
          border-image-slice: 1;
        }
      }
    }

    &_step {
      // font-family: Space Grotesk;
      color: $neutrals4;
      @include poppins;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.15em;
      text-align: left;
      text-transform: uppercase;

      @include m {
        font-size: 10px;
      }
    }

    &_text1 {
      @include poppins;
      font-size: 22px;
      font-weight: 500;
      line-height: 34.6px;
      text-align: left;

      @include m {
        font-size: 16px;
      }
    }

    &_text2 {
      color: $neutrals4;
      @include poppins;
      font-size: 15px;
      font-weight: 400;
      line-height: 28px;
      text-align: left;

      @include m {
        font-size: 14px;
      }
    }

    &_text3 {
      color: $links;
      @include poppins;
      font-size: 16.42px;
      font-weight: 400;
      line-height: 28px;
      text-align: left;

      @include dark {
        color: #a08846;
      }
    }

    &_icon {
      width: 24px;
      height: 24px;

      fill: $black;

      @include dark {
        fill: $white;
      }
    }
  }

  &__engagement {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include t {
      display: flex;
      flex-direction: column-reverse;
    }

    &__head {
      display: flex;
      justify-content: space-between;
      margin-bottom: 80px;
      align-items: center;
      @include m {
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
      }
    }

    .engagement_dashboard {
      display: grid;
      justify-content: center;
      margin-left: 90px;

      @include t {
        margin-left: 0;
      }
    }

    &_title {
      @include poppins;
      color: $neutrals2;
      font-size: 40px;
      font-weight: 500;
      line-height: 48px;
      letter-spacing: -0.01em;
      text-align: left;
      display: flex;
      align-items: center;
      margin-right: auto;

      @include dark {
        color: $coconut;
      }

      @include m {
        justify-content: center;
        @include poppins;
        font-weight: 500;
        line-height: 48px;
        text-align: center;
        margin-bottom: 30px;
        font-size: 26px;
        line-height: 48px;
        letter-spacing: -1%;
        margin-right: 0px;
      }
    }

    &_text {
      @include poppins;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: start;
      max-width: 450px;
      padding-left: 50px;

      @include t {
        padding-top: 10px;
        max-width: 100%;
        font-size: 14px;
        padding-left: 10px;
      }
    }

    &_img {
      // max-width: 566.54px;
      // max-height: 450.66px;

      width: 100%;
      height: 100%;
      padding-bottom: 20px;

      @include rmin(1023) {
        padding-right: 60px;
      }
    }
  }

  &__championsLeague {
    position: relative;

    &__swiper-flex {
      position: relative;
      display: flex;
      align-items: center;

      @include t {
        flex-direction: column;
      }
    }

    &_no-data {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    &__text-noData {
      @include poppins;
      font-size: 24px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;

      @include m {
        font-size: 14px;
      }
    }

    &_swiper {
      padding: 50px 0px !important;
    }

    &_swiper-slide {
      border-radius: 24px !important;
    }
    &__line {
      position: absolute;
      right: 15%;
      width: 318px;
      height: 1px;
      margin-right: 140px;
      margin-top: 2px;
      background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(177, 181, 196, 1) 100%);

      @include x {
        margin-right: 170px;
      }

      @include d {
        margin-right: 170px;
      }

      @include dark {
        background: linear-gradient(90deg, #141416 0%, #b1b5c4 100%);
      }

      @include r(1090) {
        display: none;
      }
    }

    .swiper {
      position: unset;
      .swiper-pagination-bullets {
        width: unset;
        height: 30px;
        top: -53px;
        display: flex;
        left: 195%;

        @include r(1601) {
          left: 160%;
        }

        @include x {
          left: 155%;
        }

        @include r(1235) {
          left: 143%;
        }

        @include d {
          left: 137%;
        }

        @include r(1145) {
          left: 130%;
        }

        @include r(1090) {
          left: 115%;
        }

        @include t {
          left: 100%;
          top: -51px;
        }

        @include r(790) {
          left: 90%;
          top: -20px;
        }

        @include r(630) {
          left: 80%;
          top: -20px;
        }

        @include r(490) {
          left: 70%;
          top: -20px;
        }
        .swiper-pagination-bullet {
          width: 13px;
          height: 13px;
          background-color: $neutrals3;

          @include dark {
            background-color: $neutrals6;
          }
        }
        .swiper-pagination-bullet-active {
          background: $primary1 !important;

          @include dark {
            background: $primary2 !important;
          }
        }
      }
    }

    &_flex {
      width: 100%;
    }

    &__text {
      /*text-align: end;*/
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @include t {
        display: block;
      }
    }

    &__flex {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;
      min-height: 570px;

      @include t {
        display: grid;
      }
    }

    &__texts {
      display: grid;
      justify-content: center;
      gap: 40px;
      margin-left: 150px;
      // background: white;

      opacity: 0;
      animation: fadeIn 3s forwards;

      @include dark {
        // background: $neutrals0;
      }

      @include d {
        margin-left: 100px;
      }

      @include t {
        margin-left: 1cqmin;
      }
    }

    @keyframes fadeIn {
      to {
        opacity: 1;
      }
    }

    &__section {
      max-width: 570px;
      gap: 40px;
      display: grid;

      @include t {
        gap: 14px;
      }
    }

    &__text1 {
      //styleName: Tagline;
      // font-family: Space Grotesk;
      color: $neutrals4;
      @include poppins;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.15em;
      text-align: left;
      padding-top: 0px;

      @include t {
        padding-top: 10px;
      }
    }

    &__text2 {
      //styleName: h2/L;
      @include poppins;
      // font-family: Sora;
      font-size: 48px;
      font-weight: 400;
      line-height: 60px;
      text-align: left;

      @include t {
        font-size: 30px;
        line-height: 40px;
      }
    }

    &__text3 {
      //styleName: Body 2/Light;
      // font-family: Sora;
      color: $neutrals4;
      @include poppins;
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      text-align: left;
    }

    &__avator {
      position: relative;
      flex-shrink: 0;
      width: 35px;
      height: 35px;
      border: 2px solid #e6e8ec;
      margin-right: 5px;
      border-radius: 50px;

      @include m {
        width: 25px;
        height: 25px;
      }

      @include dark {
        border: 2px solid #000000;
      }

      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50px;
      }
    }

    //style-box
    &_section1 {
      display: flex;
      justify-content: center;
      position: relative;
    }

    &_section3 {
      max-width: 560px;
      width: 100%;
      min-height: 513px;

      @include t {
        height: 600px;
      }

      .swiper-3d .swiper-slide-shadow {
        background: none;
      }
    }

    &_boxs {
      min-height: 513px;
      min-width: 510px;
      @include m {
        min-width: 300px;
        width: 100%;
        padding: 5px 10px;
      }
      padding: 10px 20px;
      border-collapse: separate;
      border-spacing: 0 20px;

      border-radius: 24px;
      background-color: $neutrals7;
      border: 1px solid $neutrals6;
      // box-shadow: 0px 12px 60px 0px #0000001a;

      @include dark {
        // box-shadow: 0px 12px 60px 0px #0000001a;
        background-color: #171717;
        border: 1px solid $navy1;
      }
    }

    &_boxs-1 {
      left: 59%;
      top: 15px;
      height: 470px;
      width: 200px;
      z-index: -1;
      position: absolute;
      padding: 20px;
      border-collapse: separate;
      border-spacing: 0 20px;

      border-radius: 24px;
      background-color: $neutrals7;
      border: 1px solid $neutrals6;
      box-shadow: 0px 12px 60px 0px #0000001a;

      @include t {
        left: 58%;
      }

      @include m {
        left: 40%;
        height: 300px;
      }
      @include r(500) {
        left: 37%;
      }

      @include r(400) {
        display: none;
      }

      @include dark {
        box-shadow: 0px 12px 60px 0px #0000001a;
        background-color: #171717;
        border: 1px solid $navy1;
      }
    }

    &_boxs-2 {
      opacity: 0.5;
      left: 62%;
      top: 30px;
      height: 444px;
      width: 200px;
      z-index: -2;
      position: absolute;
      padding: 20px;
      border-collapse: separate;
      border-spacing: 0 20px;

      border-radius: 24px;
      background-color: $neutrals7;
      border: 1px solid $neutrals6;
      box-shadow: 0px 12px 60px 0px #0000001a;

      @include t {
        left: 60.5%;
      }

      @include m {
        left: 43%;
        height: 270px;
      }
      @include r(500) {
        left: 41%;
      }

      @include r(450) {
        display: none;
      }

      @include dark {
        box-shadow: 0px 12px 60px 0px #0000001a;
        background-color: #171717;
        border: 1px solid $navy1;
      }
    }

    &_box {
      border-collapse: separate;
      border-spacing: 0 20px;
    }

    &_table-title {
      // font-family: Karla;
      @include poppins;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;

      @include m {
        font-size: 12px;
      }
    }

    &_table {
      margin-top: 73px;
      gap: 17px;
      display: grid;
    }

    &_tr {
      margin: 20px;
    }

    &_section-table {
      margin: 20px;
      padding: 12px;
      gap: 50px;
      border-radius: 12px;
      border: 2px;
      border: 2px solid #ffffff1a;
      background: $neutrals6;

      @include dark {
        border: 2px solid #0d0d0d1a;
        background: $navy1;
      }
    }

    &_flex1 {
      display: flex;
      align-items: center;
      gap: 10px;
      padding-left: 10px;
    }

    &_flex2 {
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 10px;
    }

    &_grid {
      display: grid;
      text-align: left;
    }

    &_table-name {
      // font-family: Karla;
      @include poppins;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;

      @include m {
        font-size: 12px;
        line-height: 9.5px;
      }
    }

    &_table-id {
      // font-family: Karla;
      color: $neutrals4;
      @include poppins;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      text-align: left;

      @include m {
        font-size: 10px;
        line-height: 9.5px;
      }
    }

    &__referral__table {
      display: table;
      width: 100%;
    }

    &__referral__table_head {
      &:first-child {
        padding-left: 30px;
      }
      &:nth-child(3) {
        padding: 6px 50px 6px 0px;
        margin: 10px;

        @include m {
          padding: 6px 10px 6px 10px;
        }
      }
      padding: 0px 10px;
      display: table-cell;
      vertical-align: middle;
    }

    &__referral__table_cell {
      &:nth-child(3) {
        padding: 6px 50px 6px 0px;
        margin: 10px;

        @include m {
          padding: 6px 10px 6px 10px;
        }
      }

      &:nth-child(4) {
        border-radius: 0px 12px 12px 0px;
      }

      &:nth-child(1) {
        border-radius: 12px 0px 0px 12px;
      }

      &:nth-child(2) {
        min-width: 200px;

        @include m {
          min-width: 100px;
        }

        @include s {
          min-width: 10px;
        }
      }

      @include m {
        padding: 6px 5px;
      }

      padding: 6px 10px;
      display: table-cell;
      vertical-align: middle;
    }
  }

  &__futureCommunity {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include t {
      display: flex;
      flex-direction: column-reverse;
    }

    &_img {
      width: 100%;
      object-fit: cover;
      height: 100%;
      margin-bottom: 0px;

      @include t {
        margin-bottom: 20px;
      }
    }

    &_section {
      max-width: 554px;
      gap: 32px;
      display: grid;
    }

    &_section-link {
      @include m {
        display: flex;
        justify-content: center;
      }
    }

    &_text {
      //styleName: Body 1/L;
      // font-family: Sora;
      color: #757185;
      @include poppins;
      font-size: 20px;
      font-weight: 400;
      line-height: 32px;
      text-align: left;

      @include m {
        font-size: 14px;
        line-height: 22px;
      }
    }

    &_text2 {
      @include main-font;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 40px;
      padding: 0px 0 24px 0;
      border-bottom: 1px solid $neutrals6;

      @include dark {
        color: #d7d6ef;
        border-bottom: 1px solid $navy1;
      }

      @include m {
        font-size: 12px;
        line-height: 27.22px;
        letter-spacing: -0.01em;
        color: #111029;

        @include dark {
          color: #d7d6ef;
        }
      }
    }

    &__body {
      background-color: $neutrals8;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 48px 32px 24px;
      border-radius: 0 0 16px 16px;
      gap: 16px;
      z-index: 1;

      @include dark {
        background-color: $primary5;
      }
    }

    &__collector {
      position: absolute;
      bottom: -35px;
      left: 32px;
      padding: 20px;
      box-shadow: 0px 12px 60px 0px #0000001a;
      width: 187px;
      height: 96px;
      z-index: 2;
      border-radius: 8px;
      height: unset !important;

      @include dark {
        background-color: $neutrals0;
      }

      @include m {
        width: 150px !important;
      }
      @include s {
        width: 100px !important;
      }

      @include r(388) {
        margin: -60px auto 0 auto;
        position: absolute;
        width: 220px !important;
        bottom: 112px;
        left: calc((100% / 2) - 123px);
      }

      .main__item {
        display: flex !important;
        width: 100% !important;
        margin: 4px 0 !important;
      }
    }

    &__main-avatar {
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }

      flex-shrink: 0;
      width: 40px;
      height: 40px;
      margin-right: 8px;

      @include m {
        width: 32px;
        height: 32px;
      }
    }

    &__main-category {
      @include caption-2;
      color: $neutrals4;
      text-align: start;

      @include dark {
        color: $neutrals5;
      }

      @include m {
        font-size: 9.53px;
        font-weight: 400;
        line-height: 16.33px;
      }
    }

    &__main-text {
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1; /* number of lines to show */
      line-clamp: 1;
      color: $primary1;

      @include dark {
        color: $primary2;
      }

      @include m {
        font-size: 10.89px;
        font-weight: 500;
        line-height: 16.33px;
      }
    }

    &__detail {
      display: flex;
      margin-left: auto;

      @include r(388) {
        margin: 130px auto 0 auto;
        width: 243px;
      }

      .sold {
        display: block;
        padding-right: 20px;
        margin-right: auto;

        &__header {
          @include main-font;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $neutrals4 !important;

          @include m {
            font-size: 9.53px;
            font-weight: 600;
            line-height: 13.61px;
          }
        }

        &__body {
          display: flex;

          .item_sold {
            @include main-font;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;

            @include dark {
              color: $neutrals3;
            }

            @include m {
              font-size: 9.53px;
              font-weight: 600;
              line-height: 13.61px;
            }
          }
        }
      }

      .artworks {
        border-left: 1px solid $neutrals6;
        padding-left: 20px;
        text-align: end;

        @include dark {
          border-left: 1px solid $navy1;
        }

        &__header {
          @include main-font;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: $neutrals4 !important;

          @include m {
            font-size: 9.53px;
            font-weight: 600;
            line-height: 13.61px;
          }
        }

        &__body {
          display: grid;

          .item_artwork {
            @include main-font;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;

            @include dark {
              color: $neutrals3;
            }

            @include m {
              font-size: 9.53px;
              font-weight: 600;
              line-height: 13.61px;
            }
          }
        }
      }
    }

    &__bioWrap {
      @include wrap-text2(3);
      -webkit-box-orient: vertical;
    }

    &_button {
      width: 100%;
      max-width: 250px;
    }
  }
}

.tw_point {
  padding: 0px 100px;

  @include m {
    padding: 0px 20px;
  }

  &__title {
    @include dm-sans;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: -0.02em;
    text-align: left;

    @include t {
      font-size: 30px;
      line-height: 43px;
      font-weight: 600;
      padding-left: 10px;
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }

  &__sections {
    padding: 30px 0px !important;

    @include m {
      padding: 20px 0px;
    }
  }

  &__sections2 {
    padding: 50px 0px !important;

    @include m {
      padding: 20px 0px;
    }
  }

  &__section1 {
    &_chart {
      &_icon {
        background: $primary1;
        color: $primary2;
        border-radius: 50%;
        font-size: 10px;
        padding: 0px 7px;

        @include dark {
          background: $primary2;
          color: $primary1;
          border-radius: 50%;
        }
      }
      &_all {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;

        @include m {
          display: flex !important;
          justify-content: center !important;
        }

        @include r(380) {
          display: grid !important;
          justify-content: center !important;
        }
      }

      &_label {
        display: grid !important;
        gap: 10px;

        @include m {
          margin-top: -40px !important;
          justify-content: center !important;
        }
      }

      &_padding {
        padding: 15px 0px !important;
      }

      display: flex;
      gap: 5px;
      align-items: center;
      &_title {
        @include poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: $neutrals4;

        @include m {
          font-size: 10px;
        }
      }

      &_Withdraw-able-Balance {
        &_color {
          background: #8e793e;
          border-radius: 50%;
          width: 10px;
          height: 10px;
        }
      }

      &_Balance-Withdrawn {
        &_color {
          background: $neutrals4;
          border-radius: 50%;
          width: 10px;
          height: 10px;
        }
      }
      &_Total-Balance {
        &_color {
          background: #373939;
          border-radius: 50%;
          width: 10px;
          height: 10px;
        }
      }
      &_Blocked-Total-Balance {
        &_color {
          background: #3772ff;
          border-radius: 50%;
          width: 10px;
          height: 10px;
        }
      }
      &_Stake-Amount {
        &_color {
          background: #065c31;
          border-radius: 50%;
          width: 10px;
          height: 10px;
        }
      }
    }
    &_boxes {
      display: flex;
      gap: 32px;

      @include t {
        display: grid;
      }
    }

    &_boxes {
      display: flex;
      gap: 32px;

      @include t {
        display: grid;
        gap: 10px;
      }
    }

    &_boxes2 {
      width: 100%;
      display: flex;
      gap: 20px;
      @include t {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
      }
    }

    &_boxes3 {
      width: 100%;
      display: flex;
      gap: 20px;

      @include m {
        gap: 5px;
      }

      @include r(370) {
        flex-wrap: wrap;
      }
    }

    &_box4 {
      width: 100%;
      display: grid;
      gap: 20px;
      margin-top: 30px;

      @include t {
        gap: 5px;
      }

      @include m {
        gap: 5px;
      }
    }

    &_list-box {
      width: 100%;
    }

    &_box {
      display: grid;
      align-items: center;
      width: 100%;
      min-height: 232px;
      padding: 24px;
      gap: 8px;
      border: 1px;
      background: $white;
      border: 1px solid $coconut2;

      &:nth-child(1) {
        @include d {
          padding: 0px;
        }
        @include t {
          padding: 24px;
        }

        @include m {
          padding: 0px;
        }
      }

      @include dark {
        background: $neutrals0;
        border: 1px solid $navy1;
      }
    }

    &_box2 {
      display: grid;
      align-items: center;
      width: 100%;
      padding: 16px;
      gap: 20px;
      border: 1px;
      background: $primary2;
      border: 1px solid $coconut2;

      @include dark {
        background: $neutrals0;
        border: 1px solid $navy1;
      }
    }

    &_text {
      //styleName: Body 2;
      @include poppins;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: $neutrals4;

      @include m {
        font-size: 14px;
      }
    }

    &_number {
      @include poppins;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: $neutrals2;
      @include wrap-text;

      @include dark {
        color: $coconut;
      }

      @include m {
        font-size: 12.37px;
        line-height: 18.55px;
        text-align: left;
      }
    }

    &_name {
      @include poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: $neutrals3;
      @include wrap-text;

      @include dark {
        color: $neutrals4;
      }

      @include m {
        font-size: 9.28px;
        line-height: 15.46px;
        text-align: left;
      }
    }

    &_icons {
      display: flex;
      align-items: center;
      gap: 20px;

      @include d {
        gap: 5px;
      }

      @include t {
        gap: 5px;
      }
    }

    &_icon {
      margin-right: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      background: #8e793e1a;
      padding: 12px;
      border-radius: 40px;

      img {
        @include m {
          width: 18px !important;
        }
      }

      @include dark {
        background: #a088461a;
      }

      @include m {
        width: 35px;
        height: 35px;
      }
    }

    &_icon2 {
      margin-right: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;

      border-radius: 40px;

      img {
        width: 38px;
        height: 38px;

        @include m {
          width: 28px;
          height: 28px;
        }
      }
    }

    &_button {
      height: 36px;
      margin: 5px;
      @include poppins;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: $neutrals8;

      @include m {
        font-size: 14px;
        color: $neutrals4;
      }
    }
  }

  &__section2 {
    padding: 90px 0px 0px 0px;

    @include m {
      padding: 30px 0px 0px 0px;
    }

    &__link {
      display: flex;
      justify-content: center;
    }

    &__link1 {
      margin-bottom: 20px;
      flex-shrink: 0;
      margin: 0 6px;
      padding: 6px 12px;
      border-radius: 5px;
      background: none;
      font-weight: 500;
      font-size: 16px;
      color: $neutrals4;
      transition: all 0.2s;
      display: flex;
      align-items: center;
      border: 1px solid $neutrals6;
      gap: 25px;
      display: flex;
      justify-content: center;
      cursor: pointer;
      max-width: 345px;

      @include main-font;

      @include m {
        margin: 0;
      }

      @include dark {
        border: 1px solid $neutrals3;
      }

      &:hover {
        color: $neutrals3;

        @include dark {
          color: $neutrals6;
        }
      }

      &__active {
        border-radius: 5px;
        padding: 7px 15px;
        background: $primary1;
        color: $primary2;

        @include dark {
          background: $primary2;
          color: $primary1;
        }
      }

      &:not(:last-child) {
        @include m {
          margin-right: 12px;
        }
      }

      &.separated {
        margin-left: auto;
      }

      .icon {
        fill: $neutrals4;
        width: 20px;
        height: 20px;
      }

      img {
        width: 20px;
        height: 20px;
        //add jsut for museum icon
        @include filter-neutrals4;
      }
    }

    &_flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 15px;

      @include m {
        display: grid;
      }
    }

    &_buttons {
      @include m {
        width: 100%;
        display: flex;
      }
    }

    &_title {
      @include dm-sans;
      font-size: 36px;
      font-weight: 700;
      line-height: 56px;
      letter-spacing: -0.02em;
      text-align: left;

      @include t {
        font-size: 20px;
        line-height: 25px;
        padding: 20px 0px;
      }
    }

    &_box {
      display: table;
      align-items: center;
      width: 100%;
      gap: 20px;
      border: 1px;
      background: $white;
      border: 1px solid $coconut2;

      @include dark {
        background: $neutrals0;
        border: 1px solid $navy1;
      }
    }

    &_like {
      background: #e4ffef;
    }

    &_Retweet {
      background: #e7dcff;
    }

    &_Comment {
      background: #daf8ff;
    }

    &_Follow {
      background: #ffe1da;
    }

    &_Hashtag {
      background: #ec917d;
    }

    &_Invite {
      background: #ffdcfe;
    }

    &_state {
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      padding: 8px 12px 8px 12px;
      gap: 8px;
      border-radius: 4px;
      color: $neutrals3;

      @include poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
    }

    &_end-cell {
      display: flex !important;
      padding: 15px 10px !important;
      justify-content: center !important;
    }

    &_end-haed {
      display: flex !important;
      justify-content: center !important;
    }

    &_note {
      padding: 0px 12px;
      @include dm-sans;
      font-size: 20px;
      font-weight: 400;
      letter-spacing: -0.02em;
      text-align: left;

      &_note2 {
        padding: 15px;
      }

      @include m {
        font-size: 16px;
      }

      &_active {
        font-weight: 700;
        color: $primary3 !important;
      }

      &_here {
        font-weight: 500;
        color: #3772ff !important;
        text-decoration: underline;
      }
    }
  }
}

.withdrawal {
  &_padding2 {
    display: grid;
    gap: 10px;
    padding-bottom: 20px;
  }

  &_input-flex {
    display: flex;
    align-items: center;
  }

  &_pc {
    display: block;
    @include m {
      display: none;
    }
  }

  &_mobile {
    display: none;
    @include m {
      display: block;
    }
  }

  &_boxes {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_box {
    display: flex;
    align-items: center;
  }

  &__title {
    @include dm-sans;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: 0;
    text-align: left;
    margin: 0px 20px;
    margin-bottom: 10px;

    @include t {
      font-size: 20px;
      line-height: 30px;
      padding-left: 10px;
    }
  }

  &_title2 {
    @include poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: $neutrals3;

    @include dark {
      color: $neutrals6;
    }

    @include m {
      font-size: 16px;
    }
  }

  &_search-field {
    width: 100%;
    height: 56px;
    padding: 0 42px 0 16px;
    background: none;
    border: 2px solid $neutrals6;
    border-radius: 0px;
    transition: border-color 0.2s;

    @include dark {
      border-color: $neutrals3;
      color: $neutrals8;
    }

    &:focus {
      border-color: $neutrals4;

      @include dark {
        border-color: $neutrals4;
      }
    }
  }

  &__width {
    width: 100%;
  }

  &_action {
    @include poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    padding: 10px;
    color: $black !important;
    width: 100%;
    display: flex;
    justify-content: center;

    @include dark {
      color: $neutrals7 !important;
    }
  }

  &_padding {
    padding-top: 30px;
  }

  &_modal {
    &_title {
      @include dm-sans;
      font-size: 22px;
      font-weight: 700;
      line-height: 56px;
      letter-spacing: 0;
      text-align: left;
      margin-bottom: 20px;

      @include t {
        font-size: 18px;
        line-height: 30px;
      }
    }

    &_text {
      @include poppins;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: center;
      color: $neutrals3;

      @include dark {
        color: $neutrals6;
      }

      @include m {
        font-size: 14px;
      }
    }

    &__button-balance {
      cursor: pointer;
    }
  }
}

.tw-stake {
  &_box {
    display: flex;
    justify-content: flex-end;
  }

  &_req-button {
    width: 50%;

    @include t {
      width: 100%;
    }
  }
}

.Stakes {
  &_btn-padding {
    padding: 0px 45px !important;
    @include dm-sans;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 16px !important;
    text-align: center !important;
  }

  &_box {
    display: grid;
    gap: 20px;

    @include m {
      gap: 10px;
    }
  }

  &_section {
    padding: 30px;
    @include m {
      padding: 20px;
    }
  }

  &_section-level {
    img {
      @include m {
        width: 25px;
        height: 25px;
      }
    }
  }

  &_field_wrap {
    position: relative;
    display: flex;
    align-items: center;

    .field_input {
      min-width: 100px;
      height: 24px;
      background: 0 0;
      border: none;
      font-size: 14px;
      font-weight: 600;
      color: $neutrals4;
      width: inherit;
      margin-right: 15px;
    }
  }

  &_title {
    @include poppins;
    font-size: 20px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
    color: $neutrals2;

    @include dark {
      color: $neutrals8;
    }

    @include m {
      font-size: 14.34px;
      font-weight: 700;
      line-height: 25.8px;
      text-align: left;
    }
  }

  &_text {
    display: flex;
    flex-wrap: wrap;
    @include poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: $neutrals3;

    @include dark {
      color: $neutrals8;
    }

    @include m {
      font-size: 10.03px;
      line-height: 17.2px;
    }
  }

  &_text-bold {
    @include poppins;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: $neutrals3;

    @include dark {
      color: $neutrals8;
    }

    @include m {
      font-size: 10.03px;
      line-height: 17.2px;
    }
  }

  &_margin-modal {
    margin-bottom: 15px;
  }

  &_grid-modal {
    display: grid;
    gap: 10px;
  }

  &_label-modal {
    @include poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
  }

  &_flex {
    display: flex;
  }
  &_max-modal {
    color: $primary3;
  }

  &_grid-mobile {
    display: flex;
    @include m {
      display: grid !important;
    }
  }

  &_flex-stake {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 5px;

    .options2 {
      margin: 0;

      .options2__list {
        padding: 0;
        position: relative;

        @include m {
          margin: 5px 0;
        }

        .actions__body {
          right: 0;
        }
      }
    }

    .profile2__btns {
      flex-grow: 0;
    }
  }

  &_buttons {
    @include m {
      display: flex;
    }
  }

  &_button-margin {
    @include m {
      margin: 0px !important;
    }
  }

  &_stakes-data {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    @include t {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  &_active-stake {
    &_seed-token {
      @include poppins;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
      color: $neutrals3;

      @include dark {
        color: $neutrals8;
      }

      @include t {
        text-align: center;
      }
    }

    &_date {
      @include poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: $neutrals3;

      @include dark {
        color: $neutrals8;
      }

      @include t {
        text-align: center;
      }
    }

    &_level {
      @include poppins;
      font-size: 13.49px;
      font-weight: 700;
      line-height: 24.29px;
      text-align: left;
      color: $neutrals2;

      @include dark {
        color: $neutrals8;
      }

      @include t {
        text-align: center;
      }

      @include m {
        flex: 33.33;
      }
    }
  }

  &_padding-modal {
    position: relative;
    padding-right: 50px !important;
  }

  &_image {
    @include dark {
      @include filter-primary2;
    }
  }

  &_token-level {
    margin-right: 70px;

    @include t {
      margin-right: 0px;
    }
  }

  &__button {
    &_actions {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
    }

    &_wrap {
      flex: 1 0 49%;
      // padding: 5px;
    }

    &_action {
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 12px;
      background: $primary2;
      border: 1px solid $coconut2;
      transition: all 0.2s ease;

      @include m {
        flex-direction: column;
      }

      &:hover,
      &.active {
        border: 1px solid $primary3;
      }

      @include dark {
        background: $neutrals0;
        border: 1px solid $navy1;

        &:hover,
        &.active {
          border: 1px solid $primary3;
        }
      }

      &.disabled {
        /*opacity: .5;*/
        background: $neutrals6;
        pointer-events: none;
        cursor: default;

        @include dark {
          background: $neutrals2;
          color: $neutrals4;
        }
      }
    }
  }
}

.custom-task {
  &__title {
    @include dm-sans;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: 0;
    text-align: left;
    margin: 0px 20px;

    @include t {
      font-size: 20px;
      line-height: 30px;
      padding-left: 10px;
    }
  }

  &__margin {
    margin: 0px 20px;
  }
}
